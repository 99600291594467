<div class="animated fadeIn">
  <a href="#/customization" class="previous">&laquo; Back</a>
    <div class="row">
      <div class="col-sm-12">
          <div class="card">
            <div class="card-header">
              <strong>Add User</strong>
              <!-- <small>Form</small> -->
            </div>
            <form autocomplete="off" [formGroup]="userForm" (ngSubmit)="addUser()">
                <div class="card-body">
                    <div class="row">
                        <div class="col-sm-6">
                            <div class="form-group">
                                <label for="company">Name</label>
                                <input type="text" class="form-control"  formControlName="name" placeholder="Enter user name">
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <div class="form-group">
                                <label for="company">Foreign Worker Details</label>
                                <input type="text" class="form-control"  formControlName="fwd" placeholder="Enter worker details">
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-6">
                            <div class="form-group">
                                <label for="company">FIN No</label>
                                <input type="text" class="form-control"  formControlName="fin" placeholder="Enter your name">
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <div class="form-group">
                                <label for="company">Worker Fin</label>
                                <input type="text" class="form-control"  formControlName="workerFin" placeholder="Enter worker details">
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="form-group col-sm-6">
                          <label for="Country Code">Work Permit Validity</label>
                          <select class="form-control" formControlName="permitValidity">
                            <!-- <option value="" disabled>Please select</option> -->
                            <option value="VALID" selected>Valid</option>
                            <option value="NOT_VALID">Not Valid</option>
                          </select>
                        </div>
                        <div class="form-group col-sm-6">
                          <label for="Landline">Wotk Permit Expiry Date</label>
                          <input type="date" class="form-control" id="landline" formControlName="permitExpiry" placeholder="Enter expiry date">
                        </div>
                    </div>
                    <div class="row">
                        <div class="form-group col-sm-6">
                          <label for="Landline">Phone Number</label>
                          <input type="text" class="form-control" id="landline" formControlName="phone" placeholder="Enter customer phone">
                        </div>
                    </div>
                </div>
                <div class="card-footer">
                    <button type="submit" class="btn btn-lg btn-primary"><i class="fa fa-dot-circle-o"></i> Submit</button>
                    <button type="reset" class="btn btn-lg btn-danger button-margin"><i class="fa fa-ban"></i> Reset</button>
                </div>
            </form>
          </div>
        </div><!--/.col-->
    </div><!--/.row-->
</div>
  
